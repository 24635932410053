import React from 'react'
import Navbar from '../inc/Navbar'
import Gallery from './Gallery'
import {Link} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
function Gallerypage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="100" color="darkgoldenrod"/>
        <div className='jagatnabdhuteacher'>
            <div className='album py-1'>
                <div className='container mt-3'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/" style={{ color: "darkgoldenrod" }} >Home</Link></li>

                            <li className="breadcrumb-item active" aria-current="page">Gallery<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg></li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </div>
            <br/>
        <Gallery/>
    </div>
  )
}

export default Gallerypage