
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';



import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contactus from './components/pages/Contactus';
import Students from './components/pages/Students';
import Faculty from './components/pages/Faculty';
import Gallerypage from './components/pages/Gallerypage';
import Aboutus from './components/pages/Aboutus';



function App() {
  return (
    <Router>
    
    <div>

     
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contactus" element={<Contactus/>}/>
      <Route axact path="/students" element={<Students/>}/>
      <Route axact path="/Faculty" element={<Faculty/>}/>
      <Route axact path="/Gallery" element={<Gallerypage/>}/>
      <Route axact path="/Aboutus" element={<Aboutus/>}/>
      
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
