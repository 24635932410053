import React from 'react'
import Navbar from "../inc/Navbar";
import Slider from '../inc/Slider';
import "./Home.css";
import ReactWhatsapp from "react-whatsapp";
import Noticeboard from './Noticeboard';
import News from './News';
import Students from "../images/jagatbandhu-students.jpg";
import Studentsdeled from "../images/DE.EL.ED STUDENTS.jpg";
import Studentsdeledcollege from "../images/d.el.ed college azimganj.jpg";
import ScrollToTop from "react-scroll-to-top";
import Gallery from './Gallery';


function Home() {
  return (
    <div>

      <Navbar />
      <br />
      <br />
      <br/>
      <ScrollToTop smooth top="100" color="darkgoldenrod" />
      <Slider />
      <div className='marquuecolor'>
        <div className="album py-0">
          <div className="container">
            <marquee>
              <h5 style={{ color: "white" }} className='mt-3'> WELCOME TO JAGATBANDHU TEACHERS TRAINING INSTITUTE</h5>
            </marquee>
          </div>
        </div>
      </div>
    


      <div className="album py-3">
        <div className="container">
          <div className="row">
            <div className="col-md-6  mt-3 me-0">
              <div className="btn-grad13 mb-0" type="submit">NOTICE BOARD</div>
              <div className='card'>

                <div className="card-body">

                  <Noticeboard />



                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="card text-white bg-secondary mb-3" >
                <div className="card-header"><center>LATEST NEWS</center></div>
                <div className="card-body">
                 <News/>
                </div>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <div className="card border-info text-light bg-white mb-3" >
                <div style={{color:"darkBlue"}} className="card-header"><center>GALLERY</center></div>
                <div className="card-body">
                <a href='students'>
                  <h5 style={{color:"black"}} className="card-title mb-3">STUDENT GALLERY <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></h5>
</a>

<a href='Faculty'>
                  <h5  style={{color:"black"}}className="card-title">FACULTY <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708"/>
  <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></h5>
</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <br />
      <div className="jagatbandhu">
                <center>
                    <h1><b>JAGATBANDHU TEACHERS TRAINING INSTITUTE</b></h1><br />
                    <h3>OUR TEACHERS</h3>
                </center>
            </div>
            <br/>
            <br/>
            <div className='album py-2'>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-4 mt-8" >
                            <div className="card-body">
                                <hr style={{ color: "darkgoldenrod" }} />
                            </div>
                        </div>
                        <div className="col-md-4 mt-8" >
                            <div className="card-body">
                                <h3 style={{color:"maroon"}} className="card-title"><center>OUR STUDENT</center></h3>
                            </div>
                        </div>
                        <div className="col-md-4 mt-8" >
                            <div className="card-body">
                                <hr style={{ color: "darkgoldenrod" }} />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="album py-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card">
                                            <img src={Students} className="card-img-top" alt="students"/>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Students Photo</h5>
                                              

                                                

                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <a href='#'>
                                        <div className="card">
                                            <img src={Studentsdeled} className="card-img-top" alt="Students"/>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Students Photo</h5>
                                              
                                                
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Studentsdeledcollege} className="card-img-top" alt="Studentsdeledcollege"/>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Students Photo</h5>
                                         
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={Students} className="card-img-top" alt="Locketdesign" />
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Students Photo</h5>
                                        
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

      <br />
      <hr/>

      <div className="album py-1">
        <div className="container">
          <h4>Gallery </h4>
          </div>

        </div>
        



        <Gallery/>
        <br/>

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <iframe src="https://www.google.com/maps/embed?pb=!4v1718093401640!6m8!1m7!1stlWvOxprGtPO5LRFzOxiYw!2m2!1d24.22258736656869!2d88.25097516730489!3f93.5839236486036!4f7.506211026714482!5f1.0228733924187488" width="600" height="400" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <br />
    </div>
  )
}

export default Home