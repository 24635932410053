import React from 'react'
import Navbar from '../inc/Navbar'
import ScrollToTop from "react-scroll-to-top";
import { Link } from "react-router-dom";
import Ourfaculty from "../images/faculty-photo.jpeg";

function Faculty() {
  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br/>
      <ScrollToTop smooth top="100" color="darkgoldenrod"/>
            <div className='jagatnabdhuteacher'>
            <div className='album py-1'>
                <div className='container mt-3'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li style={{ color: "darkgoldenrod" }} className="breadcrumb-item"><Link to="/" style={{ color: "darkgoldenrod" }} >Home</Link></li>

                            <li className="breadcrumb-item active" aria-current="page">Faculty<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg></li>
                        </ol>
                    </nav>
                    </div>
                </div>
            </div>
            <br/>
      <div className="album py-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6  mt-3">
                <div className="card-body">
                  <h4 className="card-title mb-2">JAGATBANDHU TEACHERS TRAINING INSTITUTE</h4>
                  <h6 className="card-text">Our Faculty</h6>
                 
                </div>
              </div>
           
            <div className="col-md-6  mt-3">

            
                <img src={Ourfaculty} className="card-img-top" alt="Ourfaculty"/>
                <div className="card-body">
                  
      
                </div>
             
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>
    </div>
  )
}

export default Faculty