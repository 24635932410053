import React from 'react'
import Navbar from '../inc/Navbar'
import {Link} from "react-router-dom";

import Studentsnew from "../images/jagatbandhu-students.jpg";
import Studentsdeled from "../images/DE.EL.ED STUDENTS.jpg";
import Studentsdeledcollege from "../images/d.el.ed college azimganj.jpg";

import ScrollToTop from "react-scroll-to-top";


function Students() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
      <br/>
      <br/>
      <ScrollToTop smooth top="100" color="darkgoldenrod"/>
        <div className='jagatnabdhuteacher'>
                <div className="album py-2">
                    <div className="container mt-2">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" >Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Studens Page <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708" />
                                <path fill-rule="evenodd" d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708" />
                            </svg></li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
        <div className="album py-2">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">
                                    <a href='#'>
                                        <div className="card">
                                            <img src={Studentsnew} className="card-img-top" alt="Studentsnew"/>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Studens Photo</h5>
                                              

                                                

                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6  mt-3">
                                  
                                        <div className="card">
                                            <img src={Studentsdeled} className="card-img-top" alt="Studentsd.el.ed"/>
                                            <div className="card-body">
                                                <h5 style={{ color: "black" }} className="card-title mb-1">Studens Photo</h5>
                                              
                                                
                                            </div>
                                        </div>
                                   
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="d-flex justify-content-start align-items-center mb-2">
                                <div className="col-md-6 me-2 mt-3">

                                    <div className="card">
                                        <img src={Studentsdeledcollege} className="card-img-top" alt="Studentsdeledcollege"/>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1">Studens Photo</h5>
                                         
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6  mt-3">
                                    <div className="card">
                                        <img src={Studentsnew} className="card-img-top" alt="jagatbandhutti-students"/>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1" >Studens Photo</h5>
                                        
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <br/>
            <br/>
    </div>
  )
}

export default Students