
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyDoEHgF0UAjD9LAlO7yZUgRuBHnPr2StP0",
  authDomain: "jagatbandhu-tti.firebaseapp.com",
  projectId: "jagatbandhu-tti",
  storageBucket: "jagatbandhu-tti.appspot.com",
  messagingSenderId: "812444842983",
  appId: "1:812444842983:web:e850354925108fe49c1b42",
  measurementId: "G-WQVTFNQ6PZ"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;